<template>
  <div>
    <v-btn icon @click="settingsDialog = true">
      <v-icon
        v-if="settings.graphics && settings.graphics.mode == 'low'"
        class="align-center"
      >
        mdi-cog
      </v-icon>
      <v-img
        v-else
        contain
        max-height="30"
        max-width="30"
        :src="require('@/assets/island/BtnSetting.png')"
      ></v-img>
    </v-btn>
    <v-dialog v-model="settingsDialog" max-width="290" overlay-opacity="0.8">
      <v-card>
        <div class="d-flex justify-content-between">
          <v-card-title>
            <v-icon
              v-if="settings.graphics && settings.graphics.mode == 'low'"
              class="align-center mr-2"
            >
              mdi-cog
            </v-icon>
            <v-img
              v-else
              class="mr-2"
              contain
              max-height="30"
              max-width="30"
              :src="require('@/assets/island/BtnSetting.png')"
            ></v-img>
            {{ $t("string.settings") }}</v-card-title
          >
          <v-spacer></v-spacer>
          <CloseBtn
            size="30"
            :callback="
              () => {
                settingsDialog = false;
              }
            "
          />
        </div>
        <v-card-text>
          <v-btn
            block
            large
            class="mb-3 d-flex justify-space-between"
            @click="
              lang = true;
              settingsDialog = false;
            "
          >
            <v-icon>mdi-translate</v-icon>
            {{ $t("action.changeLanguage") }}
          </v-btn>
          <v-btn
            block
            large
            class="mb-3 d-flex justify-space-between"
            @click="
              audioDialog = true;
              settingsDialog = false;
            "
          >
            <v-icon>mdi-cogs</v-icon>
            {{ $t("string.audioGraphics") }}
          </v-btn>
          
          <v-btn
            block
            large
            class="mb-3 d-flex justify-space-between"
            @click="autofix()"
          >
            <v-icon>mdi-auto-fix</v-icon>
            {{ $t("string.autofix") }}
          </v-btn>
          <v-btn
            v-if="auth.Session"
            block
            large
            color="red lighten-2"
            class="mb-3 d-flex justify-space-between"
            @click="$router.push({ name: 'PageMainLogout' })"
          >
            <v-icon>mdi-logout-variant</v-icon>
            {{ $t("action.logout") }}
          </v-btn>
        </v-card-text>
        <v-card-text>
          <SiteLogo />
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="lang" max-width="290" overlay-opacity="0.8">
      <v-card>
        <div class="d-flex justify-content-between">
          <v-card-title
            ><v-icon class="mr-2">mdi-translate</v-icon>
            {{ $t("action.changeLanguage") }}</v-card-title
          >
          <v-spacer></v-spacer>
          <CloseBtn
            size="30"
            :callback="
              () => {
                lang = false;
              }
            "
          />
        </div>
        <v-card-text>
          <v-btn
            block
            v-for="(item, i) in locales"
            :key="i"
            :color="currentLang == item.value ? 'yellow' : 'default'"
            class="mb-3"
            :large="currentLang == item.value ? false : true"
            :x-large="currentLang == item.value ? true : false"
            @click.stop="changeLocale(item)"
          >
            {{ item.name }}
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="audioDialog" max-width="290" overlay-opacity="0.8">
      <v-card>
        <div class="d-flex justify-content-between">
          <v-card-title>
            <v-icon class="mr-2">mdi-cogs</v-icon>
            {{ $t("string.audioGraphics") }}
          </v-card-title>
          <v-spacer></v-spacer>
          <CloseBtn
            size="30"
            :callback="
              () => {
                audioDialog = false;
              }
            "
          />
        </div>
        <v-card-text>
          <v-checkbox
            v-model="is_graphics_high"
            @change="saveGraphics(true)"
            class="pb-0 mb-0"
            hide-details
            off-icon="mdi-close-box"
          >
            <template v-slot:label>
              <div>
                <div class="text-h5">{{ $t("string.highGraphics") }}</div>
                <div class="text-caption">{{ $t("string.requiredHigh") }}</div>
              </div>
            </template>
          </v-checkbox>
        </v-card-text>
        <v-divider class="mx-5"></v-divider>
        <v-card-text>
          <v-checkbox
            v-model="is_sfx"
            @change="set_sfx"
            hide-details
            off-icon="mdi-close-box"
          >
            <template v-slot:label>
              <div class="text-h6">{{ $t("string.sfx") }}</div>
            </template>
          </v-checkbox>
          <v-checkbox
            v-model="is_music"
            @change="set_music"
            hide-details
            off-icon="mdi-close-box"
          >
            <template v-slot:label>
              <div class="text-h6">{{ $t("string.bgMusic") }}</div>
            </template>
          </v-checkbox>
          <v-slider
            :min="0.0"
            :max="1.0"
            step="0.01"
            class="mt-4"
            v-model="master"
            :label="$t('string.volume')"
            color="green darken-1"
            track-color="green lighten-4"
          ></v-slider>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="connectionDialog"
      persistent
      max-width="290"
      overlay-opacity="0.8"
    >
      <v-card>
        <div class="d-flex justify-content-between">
          <v-card-title> {{ $t("string.warning") }} </v-card-title>
        </div>
        <!-- <div class="text-h5">
          <span v-if="isInitiating">{{
            $t("view.PageEventLanding.textStatusInit")
          }}</span>
          <span v-if="isTesting">
            {{ $t("view.PageEventLanding.textStatusTest") }}{{ testingNo }}
          </span>
          <span v-if="textStatus"
            >{{ $t("view.PageEventLanding.textInternet")
            }}<b class="text-error">{{ $t("view.PageEventLanding.slow") }}</b
            >.
          </span>
        </div> -->
        <v-card-text>
          <div v-if="textStatus" class="text-center">
            <span class="text-h6">
              {{ $t("view.PageEventLanding.textInternet") }}
              <b class="red--text"> {{ $t("view.PageEventLanding.slow") }}</b>
            </span>

            <span>({{ textStatus }}).</span>
          </div>
          <v-checkbox
            v-model="low_internet_graphics"
            @change="saveGraphics(false)"
            class="pb-0 mb-0"
            hide-details
            color="red"
            on-icon="mdi-close-box"
            off-icon="mdi-checkbox-marked"
          >
            <template v-slot:label>
              <div>
                <div class="text-h5">{{ $t("string.highGraphics") }}</div>
                <div class="text-caption">{{ $t("string.requiredHigh") }}</div>
              </div>
            </template>
          </v-checkbox>
        </v-card-text>
        <v-divider class="mx-5"></v-divider>
        <v-card-text>
          <v-checkbox
            v-model="low_internet_sfx"
            @change="set_sfx"
            hide-details
            color="red"
            on-icon="mdi-close-box"
            off-icon="mdi-checkbox-marked"
          >
            <template v-slot:label>
              <div class="text-h6">{{ $t("string.sfx") }}</div>
            </template>
          </v-checkbox>
          <v-checkbox
            v-model="low_internet_music"
            @change="set_music"
            hide-details
            color="red"
            on-icon="mdi-close-box"
            off-icon="mdi-checkbox-marked"
          >
            <template v-slot:label>
              <div class="text-h6">{{ $t("string.this.$bgMusic") }}</div>
            </template>
          </v-checkbox>
        </v-card-text>
        <v-card-actions>
          <v-btn color="red lighten-1" text @click="resetSettings">
            {{ $t("action.noThx") }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="connectionDialog = false">
            {{ $t("action.save") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
// let this.$bgMusic = new Audio(require("@/assets/sounds/music_bg_1.mp3"));
export default {
  computed: mapState({
    auth: (state) => state.auth.data,
    settings: (state) => state.settings.data,
  }),
  components: {
    SiteLogo: () =>
      import(
        /* webpackChunkName: "component-site-logo" */ "@/components/SiteLogo.vue"
      ),
    CloseBtn: () =>
      import(
        /* webpackChunkName: "component-btn-close" */ "@/components/btns/CloseBtn.vue"
      ),
  },
  data: () => ({
    low_internet_graphics: true,
    low_internet_sfx: true,
    low_internet_music: true,
    isInitiating: true,
    isTesting: false,
    testingNo: 1,
    textStatus: null,
    connection_speed: 0,
    is_graphics_high: null,
    is_sfx: false,
    is_music: false,
    master: 0,
    music: 0,
    sfx: 0,
    audioDialog: false,
    settingsDialog: false,
    connectionDialog: false,
    lang: false,
    currentLang: null,
    locales: [
      {
        name: "Bahasa Melayu",
        label: "BM",
        value: "ms",
      },
      {
        name: "中文",
        label: "中文",
        value: "zh",
      },
      {
        name: "English",
        label: "EN",
        value: "en",
      },
    ],
    images: [
      {
        url: "https://upload.wikimedia.org/wikipedia/en/a/a9/Example.jpg",
        size: 0.027 * 1000000,
      },
      {
        url: "https://upload.wikimedia.org/wikipedia/commons/f/f0/500_kb.jpg",
        size: 0.183 * 1000000,
      },
      {
        url: "https://upload.wikimedia.org/wikipedia/commons/7/73/Glocke_1%2C_Sterbeglocke_%28Bild_2%29_600x600_500KB.jpg",
        size: 0.209 * 1000000,
      },
      {
        url: "https://upload.wikimedia.org/wikipedia/commons/a/ab/Dahlia_x_hybrida.jpg",
        size: 0.512 * 1000000,
      },
      {
        url: "https://upload.wikimedia.org/wikipedia/commons/b/b9/Pizigani_1367_Chart_1MB.jpg",
        size: 1.04 * 1000000,
      },
      {
        url: "https://upload.wikimedia.org/wikipedia/commons/1/16/AsterNovi-belgii-flower-1mb.jpg",
        size: 1.3 * 1000000,
      },
    ],
  }),
  watch: {
    master(newMaster) {
      this.updateAudio();
      let bgVolume = this.music * newMaster;
      this.$bgMusic.volume = bgVolume;
      if (bgVolume > 0) {
        this.$bgMusic.play();
      } else {
        this.$bgMusic.pause();
      }
    },
    music(newMusic) {
      this.updateAudio();
      let bgVolume = newMusic * this.master;
      this.$bgMusic.volume = bgVolume;
      if (bgVolume > 0) {
        this.$bgMusic.play();
      } else {
        this.$bgMusic.pause();
      }
    },
    sfx() {
      this.updateAudio();
    },
  },
  created() {
    //
  },
  mounted() {
    this.$bgMusic.src = require("@/assets/sounds/music_bg_1.mp3");

    if (!this.settings.audio) {
      this.master = 0.3;
      this.music = 1;
      this.sfx = 1;
      this.updateAudio();
    }
    /**
     * TODO
     * internet testing audio off
     * internet testing pop up once
     */
    // if (
    //   !this.settings.graphics ||
    //   (this.$moment
    //     .duration(this.$moment().diff(this.settings.graphics.setTime))
    //     .asHours() > 23 &&
    //     !this.settings.graphics.setByUser)
    // ) {
    //   this.test();
    // }

    this.currentLang = this.$_getLocale();

    this.is_graphics_high = this.settings.graphics
      ? this.settings.graphics.mode == "high"
        ? true
        : false
      : true;
    this.master = this.settings.audio.master;
    this.music = this.settings.audio.music;
    this.sfx = this.settings.audio.sfx;
    this.is_sfx = this.settings.audio.sfx == 0 ? false : true;
    this.is_music = this.settings.audio.music == 0 ? false : true;

    this.$bgMusic.volume = this.music * this.master;
  },
  methods: {
    updateImages() {
      this.$store.dispatch("resetSkin");
      this.$store.dispatch("resetApparel");
      this.$router.go()
    },
    autofix(){
      this.$router.push({ name: 'PageSystemFix' })
    },
    set_sfx() {
      if (this.is_sfx) {
        this.sfx = 1;
      } else {
        this.sfx = 0;
      }
    },
    set_music() {
      if (this.is_music) {
        this.music = 1;
      } else {
        this.music = 0;
      }
    },
    updateAudio() {
      let tempSettings;
      if (this.settings) {
        tempSettings = this.$_.cloneDeep(this.settings);
        tempSettings["audio"] = {
          master: this.master,
          music: this.music,
          sfx: this.sfx,
        };
      } else {
        tempSettings = {
          audio: {
            master: this.master,
            music: this.music,
            sfx: this.sfx,
          },
        };
      }
      this.$store.commit("updateSettings", tempSettings);
    },
    changeLocale(lang) {
      var href = window.location.href;
      var path = window.location.pathname.replace(
        /\/[a-z]{2}/,
        "/" + lang.value
      );
      var query = href.split("?")[1] ? `?${href.split("?")[1]}` : "";
      window.location.href = path + query;
    },
    saveGraphics(settings = false) {
      let graphics = "low";
      if (settings) {
        if (this.is_graphics_high) {
          graphics = "high";
        } else {
          graphics = "low";
        }
      } else {
        if (this.low_internet_graphics) {
          graphics = "low";
          this.is_graphics_high = false;
        } else {
          graphics = "high";
          this.is_graphics_high = true;
        }
      }
      this.updateGraphics(graphics, true);
    },
    updateGraphics(mode, setByUser) {
      let tempSettings;
      if (this.settings) {
        tempSettings = this.$_.cloneDeep(this.settings);
        tempSettings["graphics"] = {
          mode: mode,
          setTime: this.$moment().toISOString(),
          setByUser: setByUser,
        };
      } else {
        tempSettings = {
          graphics: {
            mode: mode,
            setTime: this.$moment().toISOString(),
            setByUser: setByUser,
          },
        };
      }
      this.$store.commit("updateSettings", tempSettings);
    },
    resetSettings() {
      this.updateGraphics("high", true);
      this.connectionDialog = false;
    },
    async test() {
      this.isInitiating = false;
      this.isTesting = true;
      var total = 0;
      var n = 5;
      var attempt = 0;
      for (var i = 1; i <= n; i++) {
        this.testingNo = i;
        var result = await this.checkConnectionSpeed(i - 1);
        attempt += 1;
        total += result.speed;
        if (result.duration > 2) break;
      }
      this.isTesting = false;
      var average = total / attempt;

      this.textEstimate = (20 / average / 60).toFixed(1); //assume webGL is 20Mb
      let tempSettings;
      if (this.settings) {
        tempSettings = this.$_.cloneDeep(this.settings);
        tempSettings["internetSpeed"] = average;
      } else {
        tempSettings = {
          internetSpeed: average,
        };
      }
      (tempSettings.audio = {
        master: this.master,
        music: this.music,
        sfx: this.sfx,
      }),
        this.$store.commit("updateSettings", tempSettings);
      if (
        average < 0.2 && //change here to 0.2
        (!this.settings.graphics || this.settings.graphics.mode == "high")
      ) {
        this.connectionDialog = true;
        this.is_graphics_high = false;
        this.updateGraphics("low", true);
      }
      if (average > 1) {
        this.textStatus = `${average.toFixed(1)} Mbps`;
      } else {
        average = average * 1000;
        this.textStatus = `${average.toFixed(0)} Kbps`;
      }
    },
    async checkConnectionSpeed(i) {
      var x = (this.images.length + i) % this.images.length;

      var startTime, endTime;
      var download = new Image();
      var mbps = 0;
      var duration = 0;
      startTime = new Date().getTime();
      download.src = `${this.images[x].url}?t=${startTime}`;
      await download
        .decode()
        .then(() => {
          endTime = new Date().getTime();
          duration = (endTime - startTime) / 1000;
          var size = this.images[x].size;
          mbps = size / duration / 1024 / 1024;
        })
        .catch((e) => {
          this.render("textStatus", e);
        });
      return {
        duration: duration,
        speed: mbps * 1,
      };
    },
  },
};
</script>